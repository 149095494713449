
import './App.css';
import Index from './components';
import Footer from './components/Footer';

function App() {
  return (
    <>
    <Index />
    <Footer />
    </>
  );
}

export default App;
